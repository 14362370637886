(function($) {

  let settings = null;

  //

  window.addEventListener('lightbox:open', (e) => {
    let el = e.detail.el;

    if ([...el.classList].includes('js-destini_modal_handle')) {
      onOpenModal(el.dataset.sku);
    }
  });

  // window.addEventListener('lightbox:close', (e) => {
  //   let el = e.detail.el;

  //   if ([...el.classList].includes('js-geo_modal_handle')) {
  //     onCloseModal();
  //   }
  // });

  function onOpenModal(skus) {
    let modal = document.querySelector('#destini_modal_container');
    let template = document.querySelector('#destini_modal_template');
    let content = template.content.cloneNode(true);

    content.querySelector('#destini-locator-popup').dataset.apo = skus;

    modal.innerHTML = '';
    modal.appendChild(content);
  }

  // function onCloseModal() {
  //   // let modal = document.querySelector('#destini_modal_container');
  // }

})(jQuery);